import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hallo.
    </Text>
    <p>{`Selamat datang, perkenalkan saya `}<b>{`Anshori`}</b>{` tuan rumah disini.`}<br />{`
Silakan melihat-lihat tulisan saya `}<a parentName="p" {...{
        "href": "/blog"
      }}>{`disini`}</a>{`. Apabila ingin melihat presentasi saya sila cek di `}<a parentName="p" {...{
        "href": "https://speakerdeck.com/ans4175"
      }}>{`Speakerdeck`}</a>{`.`}<br />{`
Tertarik mengobrol? Sapa saya langsung di `}<a parentName="p" {...{
        "href": "https://twitter.com/ans4175"
      }}>{`Twitter`}</a>{` ya :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      