import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Publikasi" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://core.ac.uk/download/pdf/82327564.pdf"
        }}>{`Online Marketplace for Indonesian Micro Small and Medium Enterprises Based on Social Media`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ans-4175.github.io/apa-itu-cantik/"
        }}>{`Apa itu Cantik?`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://ans-4175.github.io/pernahkah-kamu-takut/"
        }}>{`Pernahkah Kamu Takut?`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=u8X7zL7RuwA"
        }}>{`JSConf Asia 2019 - Rapid Development on Offline First Sync`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      